import React from "react";
import PropTypes from "prop-types";

import SEO from "../SEO";

export default function Pages({ children, entity, url }) {
	return (
		<>
			<SEO entity={entity} url={url}/>
			{children}
		</>
	);
}

Pages.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
	]),
	entity: PropTypes.object,
	url: PropTypes.string,
};
