import React, { useContext } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { get, last, compact } from "lodash";

import { converter, formatURL } from "../../helpers";
import { SiteMetadataContext } from "../../widgets";

export default function SEO({ lang, entity, url }) {
	const { siteMetadata } = useContext(SiteMetadataContext);

	const contentBlocks = get(entity, "content_blocks", [{ body: "" }]);
	const bodyFirstEl = get(contentBlocks, "[0].body");
	const pageTitleFull = get(entity, "page_title_full");
	const titleFull = get(entity, "title_full");
	const bodyDesc = bodyFirstEl || pageTitleFull || titleFull || "";
	const title = get(entity, "page_title_full", get(entity, "title_full", get(entity, "titleFull", "")));
	const site_name = get(entity, "seo.og.site_name", "") || "Серафимова Земля";
	const defaultImage =
		"https://storage.yandexcloud.net/serafim-uploads/content/2020/02/06/logo_4288d97f-7963-4fba-a55c-de6d3a56aa88.png";
	const seoImage = get(entity, "seo.og.image.src");
	const previewImage = get(entity, "main_image_preview.src");
	const mobileImage = get(entity, "main_image_mobile.src");
	const mainImage = get(entity, "main_image.src");

	const ogImage = seoImage || previewImage || mobileImage || mainImage || defaultImage;

	function getSeoConfig(entity, configName, defaultValue) {
		const configValue_1 = get(entity, configName, "")
    if (configValue_1 == null) {
      console.log(['getSeoConfig', configName, defaultValue, entity])
      return defaultValue;
    }
		const configValue = configValue_1.replace(/^\s+$/, "");

		return last(compact([defaultValue, configValue]));
	}

	const meta = [
		{
			name: "description",
			content: get(entity, "seo.html.description", converter.makeHtml(bodyDesc).replace(/(<([^>]+)>)/g, "")),
		},
		{
			name: "viewport",
			content: getSeoConfig(entity, "seo.html.viewport", "width=device-width, initial-scale=1.0"),
		},
		{
			name: "og:type",
			content: "website",
		},
		{
			property: "og:title",
			content: get(entity, "seo.og.title", "") || title,
		},
		{
			property: "og:image",
			content: ogImage,
		},
		{
			property: "og:image:width",
			content: "1200",
		},
		{
			property: "og:image:height",
			content: "630",
		},
		{
			name: "twitter:title",
			content: get(entity, "seo.og.title", "") || title,
		},
		{
			name: "twitter:image",
			content: ogImage,
		},
		{
			name: "twitter:card",
			content: "summary_large_image",
		},
		{
			property: "og:image:alt",
			content: get(entity, "seo.og.title", "") || title,
		},
		{
			property: "og:description",
			content: get(entity, "seo.og.description", "") || converter.makeHtml(bodyDesc).replace(/(<([^>]+)>)/g, ""),
		},
		{
			property: "og:site_name",
			content: site_name,
		},
		{
			property: "og:url",
			content: formatURL(url, get(siteMetadata, "url", process.env.REACT_APP_BASE_FRONT_URL_CYRILLIC)) || "",
		},
	];
	const noindex = get(entity, "seo.html.noindex", null);
	// eslint-disable-next-line no-unused-expressions
	noindex &&
		meta.push({
			property: "robots",
			content: noindex,
		});

	const keywords = get(entity, "seo.html.keywords", null);
	// eslint-disable-next-line no-unused-expressions
	keywords &&
		meta.push({
			name: "keywords",
			content: keywords,
		});

	// Check : og:image:width
	const ogImageWidth = get(entity, "slug", "");
	// eslint-disable-next-line no-unused-expressions
	ogImageWidth === "spaso-preobrazhenskij-monastyr" &&
		meta.push({
			property: "og:image:width",
			content: "400",
		});

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={get(entity, "seo.html.title", title)}
			defaultTitle={"Серафим.рф"}
			titleTemplate={`%s | ${site_name}`}
			meta={meta}
		>
			<link rel="canonical" href={formatURL(url, get(siteMetadata, "siteUrl", process.env.REACT_APP_BASE_FRONT_URL))} />
		</Helmet>
	);
}

SEO.propTypes = {
	lang: PropTypes.string,
	entity: PropTypes.object,
	url: PropTypes.string,
};

SEO.defaultProps = {
	lang: "ru",
	entity: {
		seo: {
			og: {},
			html: {},
		},
		page_title_full: "",
		title_full: "",
		content_blocks: [],
		main_image: "",
		twitter: {},
	},
	url: "",
};
